<template>

  <div>

    <wp-items-list-add-new
      :is-add-new-wp-item-sidebar-active.sync="isAddNewWpItemSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <wp-items-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      class="d-none"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refWpItemListTable"
        class="position-relative"
        :items="fetchWpItems"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>

        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.icon_url"
                :text="avatarText(data.item.title)"
                :to="{ name: $props.contentType+'-view', params: { id: data.item.id } }"
                rounded
              />
            </template>
            <b-link
              :to="{ name: $props.contentType+'-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
              :title=data.item.title
            >
              <b-badge
                v-if="data.item.featured"
                pill
                variant="primary"
                class="text-capitalize"
              >
                <feather-icon
                  icon="StarIcon"
                />
              </b-badge>
              {{ data.item.title.length > 40 ? data.item.title.slice(0, 40).trim() + '...' : data.item.title.trim()}}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Date published -->
        <template #cell(published_at)="data">
          <b-media vertical-align="center">
            {{ formatDate(data.item.published_at) }}
          </b-media>
        </template>

        <!-- Column: Date updated -->
        <template #cell(last_imported)="data">
          <b-media vertical-align="center">
            {{ formatDate(data.item.last_imported) }}
          </b-media>
        </template>

        <!-- Column: Total Views -->
        <template #cell(all_analytics_count)="data">
          <b-media
            vertical-align="center"
            class="text-center"
          >
            {{ data.item.all_analytics_count }}
          </b-media>
        </template>

        <!-- Column: Views (Prev 28 Days) -->
        <template #cell(last_28_days_analytics_count)="data">
          <b-media
            vertical-align="center"
            class="text-center"
          >
            {{ data.item.last_28_days_analytics_count }}
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: $props.contentType+'-view', params: { id: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: $props.contentType+'-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalWpItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BDropdown, BDropdownItem, BPagination, BAvatar, BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import formatDateMixin from '@core/mixins/ui/date'
import WpItemsListFilters from './WpItemsListFilters.vue'
import useWpItemsList from './useWpItemsList'
import WpItemsListAddNew from './WpItemsListAddNew.vue'

export default {
  components: {
    BBadge,
    WpItemsListFilters,
    WpItemsListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatar,

    vSelect,
  },
  mixins: [formatDateMixin],
  props: {
    contentType: {
      type: String,
      required: true,
    },
    contentTypeSingular: {
      type: String,
      required: true,
    },
    contentName: {
      type: String,
      required: true,
    },
    contentNameSingular: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isAddNewWpItemSidebarActive = ref(false)

    const {
      fetchWpItems,
      tableColumns,
      perPage,
      currentPage,
      totalWpItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWpItemListTable,
      refetchData,

      // UI
      resolveWpItemStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,
    } = useWpItemsList(props)

    return {

      // Sidebar
      isAddNewWpItemSidebarActive,

      fetchWpItems,
      tableColumns,
      perPage,
      currentPage,
      totalWpItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWpItemListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveWpItemStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
